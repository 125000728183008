/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  title: string;
  description: string;
  route: string;
  image: string;
}

export default class StandaloneChannelsOverviewCard extends Component<Args> {
  @service declare appService: any;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::Overview::Card': typeof StandaloneChannelsOverviewCard;
    'standalone/channels/overview/card': typeof StandaloneChannelsOverviewCard;
  }
}
