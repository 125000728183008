/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    header?: string;
    copy: string;
  };
}

const TermsAndConditions = templateOnlyComponent<Signature>();
export default TermsAndConditions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::TermsAndConditions': typeof TermsAndConditions;
    'signup/teams/pricing5/components/terms-and-conditions': typeof TermsAndConditions;
  }
}
