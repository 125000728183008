/* import __COLOCATED_TEMPLATE__ from './trigger-config.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskTriggerConfig extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked manageAssignmentTrigger = this.zendeskConfig.ticketsIntegration.manageAssignmentTrigger;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get triggerUrl() {
    if (!this.ticketsSetupData.live_trigger?.id) {
      return null;
    }

    return `https://${this.zendeskConfig.subdomain}.zendesk.com/admin/objects-rules/rules/triggers/${this.ticketsSetupData.live_trigger.id}`;
  }

  @action openSectionChanged() {
    this.manageAssignmentTrigger = this.zendeskConfig.ticketsIntegration.manageAssignmentTrigger;
  }

  @action
  toggleManageAssignmentTrigger() {
    this.manageAssignmentTrigger = !this.manageAssignmentTrigger;
  }

  @action
  async updateManageAssignmentTrigger() {
    try {
      await this.zendeskConfig.ticketsIntegration.updateManageAssignmentTrigger(
        this.manageAssignmentTrigger,
      );
      this.notificationsService.success('Trigger configuration updated successfully');
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default:
          "Something went wrong and we couldn't change your trigger setting. Please try again",
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::TriggerConfig': typeof StandaloneZendeskTriggerConfig;
    'standalone/zendesk/setup/trigger-config': typeof StandaloneZendeskTriggerConfig;
  }
}
