/* import __COLOCATED_TEMPLATE__ from './data-field-modal.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type UserField, type TicketField } from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type { ZendeskFieldType } from 'embercom/services/fin-standalone-service';

import type Attribute from 'embercom/models/attribute';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { action } from '@ember/object';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import { put } from 'embercom/lib/ajax';
interface Args {
  field: UserField | TicketField;
  onClose: () => void;
}

const FIELD_TYPE_LABELS: Record<ZendeskFieldType, string> = {
  text: 'Text',
  textarea: 'Text',
  checkbox: 'Boolean',
  date: 'Date',
  integer: 'Number',
  decimal: 'Decimal',
  regexp: 'Text',
  dropdown: 'List',
  multiselect: 'List',
  lookup: 'Relationship',
  tagger: 'List',
};

export default class DataFieldModal extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;
  @service declare store: any;
  @service declare notificationsService: any;

  get fieldType() {
    return FIELD_TYPE_LABELS[this.args.field.type];
  }

  conversationAttribute: ConversationAttributeDescriptor | undefined;
  userAttribute: Attribute | undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);

    if ('intercom_cvda' in this.args.field) {
      if (this.args.field.intercom_cvda) {
        this.conversationAttribute = this.store.push(
          this.store.normalize('conversation-attributes/descriptor', this.args.field.intercom_cvda),
        ) as ConversationAttributeDescriptor;
      }
    }

    if ('intercom_cda' in this.args.field) {
      if (this.args.field.intercom_cda) {
        this.userAttribute = this.store.push(
          this.store.normalize('attribute', this.args.field.intercom_cda),
        ) as Attribute;
      }
    }
  }

  @action async saveConversationAttribute() {
    if (this.conversationAttribute) {
      try {
        if (
          this.conversationAttribute.listOptions.every((option: ListOption) => option.description)
        ) {
          this.conversationAttribute.isClassificationAttribute = true;
        }

        await this.conversationAttribute.save();

        // @ts-expect-error we know this is a ticket field
        this.args.field.intercom_cvda = this.conversationAttribute.serialize();

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: 'Something went wrong and we couldn’t save the attribute.',
        });
      }
    }
  }

  @action async remove() {
    if (this.userAttribute) {
      try {
        await put('/ember/data_attribute_settings/archive', {
          app_id: this.appService.app.id,
          id: this.userAttribute.cdaId,
        });

        // @ts-expect-error we know this is a user field
        this.args.field.intercom_cda = undefined;

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: 'Something went wrong and we couldn’t remove the attribute.',
          412: 'This attribute is currently in use, check your workflows and remove it from there first.',
        });
      }
    } else if (this.conversationAttribute) {
      try {
        this.conversationAttribute.archived = true;
        this.conversationAttribute.order = 0;
        await this.conversationAttribute.save();

        // @ts-expect-error we know this is a ticket field
        this.args.field.intercom_cvda = undefined;

        this.args.onClose();
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: 'Something went wrong and we couldn’t remove the attribute.',
          412: 'This attribute is currently in use, check your workflows and remove it from there first.',
        });
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::DataFieldModal': typeof DataFieldModal;
    'standalone/zendesk/data-field-modal': typeof DataFieldModal;
  }
}
