/* import __COLOCATED_TEMPLATE__ from './data-fields-section.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';
import { type UserField, type TicketField } from 'embercom/services/fin-standalone-service';

interface Signature {
  Args: {
    title: string;
    isLoading: boolean;
    fields: Array<UserField | TicketField>;
    selectedField: UserField | TicketField | undefined;
    selectField: (field: UserField | TicketField) => void;
    unsynchronizedFields: Array<UserField | TicketField>;
    synchronizeField: (field: UserField | TicketField) => void;
  };
}

const DataFieldSection = templateOnlyComponent<Signature>();

export default DataFieldSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::DataFieldsSection': typeof DataFieldSection;
  }
}
