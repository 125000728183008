/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    color: 'green' | 'yellow' | 'gray';
    icon: InterfaceIconName;
    text: string;
    isLoading?: boolean;
  };
  Element: HTMLElement;
}

export default class Pill extends Component<Signature> {
  get colorClasses() {
    switch (this.args.color) {
      case 'green':
        return 'bg-green-light text-green-dark';
      case 'yellow':
        return 'bg-yellow-light text-yellow-dark';
      case 'gray':
        return 'bg-gray-light text-gray-dark';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::Pill': typeof Pill;
  }
}
