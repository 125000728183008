/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* template-lint-disable no-bare-strings */
/* eslint-disable ember/no-empty-glimmer-component-classes */

import Component from '@glimmer/component';

interface Args {}

export default class StandaloneChannelsOverview extends Component<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::Overview': typeof StandaloneChannelsOverview;
    'standalone/channels/overview': typeof StandaloneChannelsOverview;
  }
}
